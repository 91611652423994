import React from "react";
import {TouchableOpacity} from "react-native";
import TextPoppinsBold from "../Text/PoppinsTextBold";
import {Colors} from "../../../constant/Colors";

interface ButtoDefaultProps {
	onPress: React.ComponentProps<typeof TouchableOpacity>[ "onPress" ];
}

interface MainButtonProps extends ButtoDefaultProps {
	title: string;
}

export const MainButton = ({onPress, title}: MainButtonProps): React.ReactElement => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={{
				width: "100%",
				height: 60,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: Colors.bg.mainSuccess,
				borderRadius: 8,
			}}>
			<TextPoppinsBold
				style={{color: "#fff", fontSize: 16, textAlign: "center", lineHeight: 60}}>{title}</TextPoppinsBold>
		</TouchableOpacity>
	)
}

export const MainButtonInvert = ({onPress, title}: MainButtonProps): React.ReactElement => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={{
				width: "100%",
				height: 50,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'white',
				borderRadius: 8,
				marginTop: 10,
			}}>
			<TextPoppinsBold
				style={{color: Colors.bg.mainSuccess, fontSize: 16, textAlign: "center", lineHeight: 60}}>{title}</TextPoppinsBold>
		</TouchableOpacity>
	)
}

export const DangerButton = ({onPress, title}: MainButtonProps): React.ReactElement => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={{
				width: "100%",
				height: 60,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: Colors.bg.mainWarning,
				borderRadius: 8,
			}}>
			<TextPoppinsBold
				style={{color: "#fff", fontSize: 16, textAlign: "center", lineHeight: 60}}>{title}</TextPoppinsBold>
		</TouchableOpacity>
	)
}
