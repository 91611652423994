import {Text as Defaultext} from "react-native";

type TextProps = Defaultext['props'];

const TextPoppinsRegular = (props: TextProps) => {
	const {style, ...rest} = props;
	return <Defaultext {...rest} style={[{fontFamily: 'Poppins_400Regular'}, style]}/>
}

export default TextPoppinsRegular
