import React from "react";
import {TextInput, View} from "react-native";

interface BaseTextInputMainProps {
    placeholder: string;
    onChangeText: React.ComponentProps<typeof TextInput>[ "onChangeText" ];
    value: string;
    secureTextEntry?: boolean;
    keyboardType?: "default" | "email-address" | "numeric" | "phone-pad" | "number-pad" | "decimal-pad" | "visible-password";
}

export const BaseTextInput = ({
                                  placeholder,
                                  onChangeText,
                                  value,
                                  secureTextEntry,
                                  keyboardType = "default"
                              }: BaseTextInputMainProps): React.ReactElement => {
    return (
        <View style={{width: "100%", height: 50, marginBottom: 10}}>
            <TextInput style={{width: "100%", height: "100%", borderWidth: 0.3, borderRadius: 8, paddingHorizontal: 10}}
                       onChangeText={onChangeText}
                       secureTextEntry={secureTextEntry} value={value} placeholder={placeholder}
                       keyboardType={keyboardType}/>
        </View>
    )
}

export const BaseTextInputInvert = ({
                                        placeholder,
                                        onChangeText,
                                        value,
                                        secureTextEntry,
                                        keyboardType = "default"
                                    }: BaseTextInputMainProps): React.ReactElement => {
    return (
        <View style={{width: "100%", height: 50, marginBottom: 10}}>
            <TextInput style={{
                width: "100%",
                height: "100%",
                borderWidth: 0.3,
                borderRadius: 8,
                paddingHorizontal: 10,
                borderColor: 'white',
                backgroundColor: 'white'
            }}
                       onChangeText={onChangeText}
                       secureTextEntry={secureTextEntry} value={value} placeholder={placeholder} placeholderTextColor={"gray"}
                       keyboardType={keyboardType}/>
        </View>
    )
}
