export const Colors = {
	bg: {
		default: '#eaeeee',
		mainSuccess: "#2B99FF",
		mainSuccessLv1: "#5CB0FF",
		mainDanger: "#FF9500",
		mainWarning: "#E50000",
		inactive: "#D4D2D5"
	}
}

export const defaultBgColor = Colors.bg.default;
