import {ActivityIndicator, Image, View} from "react-native";

import {MainButtonInvert} from "../../component/molecules/Button/BaseButton";
import {BaseTextInputInvert} from "../../component/molecules/Textinput/BaseTextInput";
import React, {useState} from "react";
import {PlainApiCall} from "../../util/helper/ApiCall";
import {Colors} from "../../constant/Colors";
import {LoginResponseDatType} from "../../util/type/LoginResponseType";
import {useToast} from "react-native-toast-notifications";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useReduxDispatch} from "../../redux";
import {setUser} from "../../redux/state/userState";
import {setLogin} from "../../redux/state/LoginState";
import OtpInput from "../../component/Otp/OtpInput";
import TextPoppinsBold from "../../component/molecules/Text/PoppinsTextBold";

type StateInput = "phone" | "otp"
export default function LoginScreen(): React.ReactElement {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [_stateInput, _setStateInput] = useState<StateInput>("phone");
    const [_otpSign, _setOtpSign] = useState("");
    const [_userId, _setUserId] = useState("");

    const
        toast = useToast();
    const dispatch = useReduxDispatch();

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            const dataPost = new FormData();
            dataPost.append("email", email);
            dataPost.append("password", password);
            const {data} = await PlainApiCall.post(`user/login`, dataPost);
            const dataResponse: LoginResponseDatType = data;
            setIsLoading(false);
            if (dataResponse.message === "Success") {
                if (dataResponse.data.status_member === "premium") {
                    await AsyncStorage.setItem("userData", JSON.stringify(dataResponse.data));
                    console.log(dataResponse.data);
                    dispatch(setUser(dataResponse.data));

                } else {
                    toast.show("Anda tidak memiliki akses premium", {type: "danger"});
                }
            } else {
                toast.show(dataResponse.message, {type: "warning"})
            }

        } catch (e) {
            console.log(e);
        }
    };

    async function _handleValidateotp(otp: string) {
        setIsLoading(true);
        const dataPost = {
            otp: otp,
            id: _userId,
            otp_sign: _otpSign
        };
        const {data} = await PlainApiCall.post(`v2/user/register/verifikasi`, dataPost);
        if (data.message === "Success") {
            const dataResponse: LoginResponseDatType = data;
            toast.show("Berhasil", {type: "success"});
            if (dataResponse.data.status_member === "premium") {
                await AsyncStorage.setItem("userData", JSON.stringify(dataResponse.data));
                console.log(dataResponse.data);
                dispatch(setUser(dataResponse.data));
                dispatch(setLogin(true));
                toast.show("Berhasil", {type: "success"});
            } else {
                toast.show("Anda tidak memiliki akses premium", {type: "danger"});
            }
        } else {
            toast.show(data.message, {type: "warning"})
        }
    }

    async function _handlegetOtp() {
        setIsLoading(true);
        const dataPost = {
            phone: email,
        };

        const {data} = await PlainApiCall.post(`v2/user/login/whatsapp`, dataPost);
        setIsLoading(false);
        if (data.message === "lanjutkan proses verifikasi akun") {
            _setStateInput("otp");
            _setUserId(data.data.id);
            _setOtpSign(data.data.otp_sign);
        } else {
            toast.show(data.message, {type: "warning"})
        }

    }

    function _otpDone(otp: string) {
        _handleValidateotp(otp);
    }

    function _renderInputNomor() {
        if (_stateInput !== "phone") return
        return (
            <>
                <BaseTextInputInvert placeholder={"Nomor Handphone"} onChangeText={(text) => {
                    setEmail(text)
                }} value={email} keyboardType={"numeric"}/>
                <MainButtonInvert title={"Login"} onPress={_handlegetOtp}/>
            </>
        )
    }

    function _renderInputOtp() {
        if (_stateInput !== "otp") return
        return (
            <View style={{
                width:"100%",
                alignItems: "center",
            }}>
                <OtpInput length={4} finalOtp={_otpDone} keyboadType={"numeric"}/>
                <TextPoppinsBold style={{color: "white", fontSize: 16, marginTop: 16}}>
                    Masukkan Kode OTP
                </TextPoppinsBold>
            </View>
        )
    }

    function _renderLoading() {
        return <ActivityIndicator size={"large"} color={"white"}/>
    }


    return (
        <View style={{flex: 1, justifyContent: "center", alignItems: 'center', backgroundColor: Colors.bg.mainSuccess}}>
            <Image style={{width: 200, height: 80, marginBottom: 50}} source={require('../../../assets/logo.png')} resizeMode={"contain"}/>
            <TextPoppinsBold style={{fontSize: 18, marginBottom: 20, textAlign: 'center', color: 'white'}}>Bangbeli
                Merchant Acquisition
                Page</TextPoppinsBold>
            <View style={{width: "80%"}}>
                {
                    isLoading ? _renderLoading() : (
                        <>
                            {_renderInputNomor()}
                            {_renderInputOtp()}
                        </>
                    )
                }

            </View>
        </View>
    )
}
