import React, {useEffect, useLayoutEffect, useState} from "react";
import {Dimensions, View} from "react-native";
import {Colors, defaultBgColor} from "../../../constant/Colors";

type DefaultBaseViewProps = {
	children: React.ReactNode;
};
const DefaultBaseView = ({children}: DefaultBaseViewProps): React.ReactElement => {
	const [width, setWidth] = useState(Dimensions.get("window").width);
	useLayoutEffect(() => {
		const newWidth = Dimensions.get("window").width;
	}, []);
	return (
		<View style={{flex: 1, backgroundColor: defaultBgColor, alignItems: "center"}}>
			<View style={{width: width > 720 ? 720 : "100%", height: "100%", backgroundColor: "white"}}>
				{children}
			</View>
		</View>
	)
}

export default DefaultBaseView;
