import React, {useEffect, useState} from "react";
import {ActivityIndicator, Dimensions, FlatList, View} from "react-native";
import TextPoppinsMedium from "../../component/molecules/Text/PoppinsTextMedium";
import TextPoppinsRegular from "../../component/molecules/Text/PoppinsTextRegular";
import {Colors} from "../../constant/Colors";
import {useReduxDispatch, useReduxSelector} from "../../redux";
import {getUserData, resetUser, setUser} from "../../redux/state/userState";
import {formatRupiah} from "../../util/function/formatRupiah";
import {ApiCall} from "../../util/helper/ApiCall";
import {ListDownlineResponse} from "../../util/type/ListDownlineDataType";
import {HomeMitraCard} from "../../component/HomeCard";
import {MainRoutes, RootStackScreenProps} from "../../util/type/routingNavigation";
import {useIsFocused} from "@react-navigation/native";
import {DataLoginResponseType} from "../../util/type/LoginResponseType";
import AsyncStorage from "@react-native-async-storage/async-storage";

const {height} = Dimensions.get("window");
export default function HomeScreen({navigation}: RootStackScreenProps<MainRoutes.Home>): React.ReactElement {
	const datamember = useReduxSelector(getUserData);
	const isFocused = useIsFocused();
	const dispatch = useReduxDispatch();

	const [mitra, setMitra] = useState<ListDownlineResponse>();
	const [isLoading, setIsLoading] = useState(true);

	const getDataDownline = async () => {
		try {
			const dataPost = {
				username:""
			}
			const {data} = await ApiCall.post('v1/poin/list_downline', dataPost);
			setMitra(data.data);
			setIsLoading(false);
		} catch (e) {
			setMitra(undefined);
			if (e.response.status === 401) {
				if (e.response.status === 401) {
					setLogout();
				}
			}
			console.log(e)
		}
	}
	const setLogout = async () => {
		await AsyncStorage.clear();
		dispatch(resetUser());
	}

	const getUserDetail = async () => {
		try {
			const {data} = await ApiCall.post('v1/members/show');
			const result: DataLoginResponseType = data.data;
			dispatch(setUser(result));
		} catch (e) {
			if (e.response.status === 401) {
				setLogout();
			}
			console.log(e)
		}
	}

	useEffect(() => {
		getDataDownline()
		getUserDetail()
	}, [isFocused])


	return (
		<View style={{width: "100%"}}>
			<View style={{
				width: "100%",
				backgroundColor: Colors.bg.mainSuccessLv1,
				alignItems: 'center',
				paddingBottom: 10,
				paddingTop: 50,
			}}>
				<TextPoppinsRegular>Sisa Saldo <TextPoppinsMedium
					style={{fontSize: 24}}>Rp {formatRupiah(datamember.saldo)}</TextPoppinsMedium></TextPoppinsRegular>
			</View>
			<View style={{width: "100%", height: height - 120, paddingHorizontal: 10}}>
				<TextPoppinsMedium>List Mitra</TextPoppinsMedium>
				{
					isLoading
						?
						<ActivityIndicator style={{marginTop: '20%'}} size={"large"} color={Colors.bg.mainSuccess}/>
						:
						<FlatList showsVerticalScrollIndicator={false} data={mitra?.list_member}
								  renderItem={({item}) => (
									  // <TextPoppinsRegular>{item.username}</TextPoppinsRegular>
									  <HomeMitraCard onPress={() => {
										  navigation.navigate(MainRoutes.Kirim, {id: item.id})
									  }} poin={item.poin_komisi} title={item.username}/>
								  )}/>
				}
			</View>

		</View>
	)
}
