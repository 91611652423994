import React from "react";
import {Image, TouchableOpacity, View} from "react-native";
import TextPoppinsMedium from "./molecules/Text/PoppinsTextMedium";

type HomeMitracardProps = {
	title: string,
	onPress: React.ComponentProps<typeof TouchableOpacity>["onPress"]
	poin: number,
}
export const HomeMitraCard = ({title, poin, onPress}: HomeMitracardProps): React.ReactElement => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={{
				width: "100%",
				height: 80,
				elevation: 5,
				backgroundColor: "white",
				marginBottom: 10,
				flexDirection: "row",
				alignItems: 'center',
				paddingHorizontal: 10,
				borderRadius: 8,
			}}>
			<View style={{width: "70%"}}>
				<TextPoppinsMedium style={{fontSize: 18}}>{title}</TextPoppinsMedium>
			</View>
			<View style={{width: "30%", flexDirection: "row-reverse"}}>
				<TextPoppinsMedium style={{textAlign: "right"}}>{poin}</TextPoppinsMedium>
				<Image source={{uri: "https://bacotan.me/poin.png"}} style={{width: 20, height: 20,marginRight:5}}/>
			</View>
		</TouchableOpacity>
	)
}
