import React, {useEffect} from "react";
import {ActivityIndicator, View} from "react-native";
import TextPoppinsMedium from "../../../component/molecules/Text/PoppinsTextMedium";
import TextPoppinsRegular from "../../../component/molecules/Text/PoppinsTextRegular";
import {MainRoutes, RootStackScreenProps} from "../../../util/type/routingNavigation";
import {BaseTextInput} from "../../../component/molecules/Textinput/BaseTextInput";
import {ApiCall} from "../../../util/helper/ApiCall";
import {Colors} from "../../../constant/Colors";
import {useReduxDispatch, useReduxSelector} from "../../../redux";
import {useToast} from "react-native-toast-notifications";
import {ResponseMitraDataType} from "../../../util/type/mitradataType";
import {setusernameMitra} from "../../../redux/state/mitraState";
import {MainButton} from "../../../component/molecules/Button/BaseButton";
import {Modalize} from "react-native-modalize";
import {formatRupiah} from "../../../util/function/formatRupiah";
import {getUserData, resetUser} from "../../../redux/state/userState";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function KirimSaldoScreen({
                                             navigation,
                                             route
                                         }: RootStackScreenProps<MainRoutes.Kirim>): React.ReactElement {

    const [nominal, setNominal] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [pin, setPin] = React.useState('');
    const [loadingSend, setLoadingSend] = React.useState(false);

    const dispatch = useReduxDispatch();
    const toast = useToast();
    const userData = useReduxSelector(getUserData);

    const modalizeRef = React.useRef<Modalize>(null);

    const getDataMembers = async () => {
        try {
            const dataPost = {
                id: route.params.id
            }
            const {data} = await ApiCall.post('v1/poin/member_by_id', dataPost);
            const responseData: ResponseMitraDataType = data;
            setLoading(false);
            if (responseData.message === 'Success') {
                dispatch(setusernameMitra(responseData.data.username));
            } else {
                toast.show(responseData.message, {type: "danger"});
            }
        } catch (e) {
            console.log(e);
        }
    }

    const sendSaldo = async () => {
        try {
            const dataPost = {
                id: route.params.id,
                saldo: nominal,
                pin: pin
            }

            const {data} = await ApiCall.post('v1/poin/kirim_saldo', dataPost);
            const responseData: ResponseMitraDataType = data;
            if (responseData.message === 'Success') {
                toast.show(responseData.message, {type: "success"});
                modalizeRef.current?.close();
            } else {
                toast.show(responseData.message, {type: "danger"});
            }
            setLoadingSend(false);
        } catch (e) {
            if (e.response.status === 401) {
                setLogout();
            }
            console.log(e);
        }
    }
    const setLogout = async () => {
        await AsyncStorage.clear();
        dispatch(resetUser());
    }

    useEffect(() => {
        getDataMembers();
    }, []);

    if (loading) {
        return <ActivityIndicator size={"small"} color={Colors.bg.mainSuccess}/>
    }

    return (
        <View style={{flex: 1}}>
            <View style={{width: "100%", alignItems: 'center'}}>
                <View style={{width: "80%", marginTop: 20}}>
                    <BaseTextInput
                        placeholder="Nominal saldo yang akan dikirim"
                        onChangeText={(text) => {
                            setNominal(text);
                        }}
                        keyboardType={'numeric'}
                        value={nominal}
                    />

                    <View style={{marginTop: 10}}>
                        <MainButton title={"Kirim Saldo"} onPress={() => {

                            if (parseInt(nominal) <= 0 || nominal === '') {
                                toast.show("Nominal saldo tidak boleh kosong", {type: "danger"});
                            } else {
                                if (userData.saldo < parseInt(nominal)) {
                                    toast.show("Saldo anda tidak mencukupi", {type: "danger"});
                                } else {
                                    modalizeRef.current?.open();
                                }
                            }
                        }}/>
                    </View>
                </View>
            </View>
            <Modalize ref={modalizeRef} modalHeight={300}>
                <View style={{
                    padding: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: "space-between",
                    height: 300
                }}>
                    <TextPoppinsMedium style={{fontSize: 20, marginBottom: 10}}>
                        Konfirmasi Kirim Saldo
                    </TextPoppinsMedium>
                    <View style={{width: "80%"}}>
                        <TextPoppinsRegular style={{fontSize: 16, marginBottom: 10}}>
                            Nominal saldo yang akan dikirim : <TextPoppinsMedium
                            style={{fontSize: 20}}>{formatRupiah(parseInt(nominal))}</TextPoppinsMedium>
                        </TextPoppinsRegular>
                        <BaseTextInput
                            placeholder={"Pin Transaksi"}
                            onChangeText={(text) => {
                                setPin(text)
                            }}
                            value={pin}
                            keyboardType={"numeric"} secureTextEntry={true}/>
                        {
                            loadingSend
                                ?
                                <ActivityIndicator size={"small"} color={Colors.bg.mainSuccess}/>
                                :
                                <MainButton title={"Confirm"} onPress={() => {
                                    setLoadingSend(true);
                                    if (pin.length < 6) {
                                        toast.show("Pin transaksi tidak boleh kosong", {type: "danger"});
                                        setLoadingSend(false);
                                    } else {
                                        sendSaldo();
                                    }
                                }}/>
                        }
                    </View>
                </View>
            </Modalize>
        </View>
    )
}
