import DefaultBaseView from "./src/component/molecules/base";
import Routing from "./src/routing";
import {ToastProvider} from "react-native-toast-notifications";
import {Provider} from "react-redux";
import store, {persistor} from "./src/redux";
import {PersistGate} from "redux-persist/integration/react";
import React, {Suspense} from "react";
import {ActivityIndicator, View} from "react-native";
import {Colors} from "./src/constant/Colors";

function _renderLoading() {
    return (
        <DefaultBaseView>
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.bg.mainSuccess
            }}>
                <ActivityIndicator size="large" color={"white"}/>
            </View>
        </DefaultBaseView>
    );
}

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <DefaultBaseView>
                    <ToastProvider>
                        <Suspense fallback={_renderLoading()}>
                            <Routing/>
                        </Suspense>

                    </ToastProvider>
                </DefaultBaseView>

            </PersistGate>

        </Provider>
    );
}
