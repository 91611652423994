import React from "react";
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {MainRoutes, MainTabParamList, MainTabRoutes, RootStackParamList} from "../util/type/routingNavigation";
import LoginScreen from "../screen/auth/lognScreen";
import AppLoading from "expo-app-loading";
import {Poppins_400Regular, Poppins_500Medium, Poppins_700Bold, useFonts} from '@expo-google-fonts/poppins';
import {useReduxSelector} from "../redux";
import HomeScreen from "../screen/mainScreen/HomeScreen";
import ProfileScreen from "../screen/mainScreen/ProfileScreen";
import RegisterScreen from "../screen/mainScreen/RegisterScreen";

import Ionicons from '@expo/vector-icons/Ionicons';
import {Colors} from "../constant/Colors";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {getIsuserLogedIn} from "../redux/state/userState";
import KirimSaldoScreen from "../screen/mainScreen/mitra/KirimSaldo";
import {getMitraUsername} from "../redux/state/mitraState";

const Stack = createNativeStackNavigator<RootStackParamList>();
export default function Routing(): React.ReactElement {

	const isLogin = useReduxSelector(getIsuserLogedIn)
	const userNameMitra = useReduxSelector(getMitraUsername)

	const [fontsLoaded] = useFonts({
		Poppins_400Regular,
		Poppins_500Medium,
		Poppins_700Bold
	});


	if (!fontsLoaded) {
		return <AppLoading/>;
	}


	return (
		<NavigationContainer fallback={null}>
			<Stack.Navigator>
				{
					!isLogin
						?
						<Stack.Screen name={MainRoutes.Login} component={LoginScreen} options={{
							headerShown: false
						}}/>
						:
						<>
							<Stack.Screen name={MainRoutes.Home} component={MainButtomTab} options={{
								headerShown: false
							}}/>
							<Stack.Screen name={MainRoutes.Kirim} component={KirimSaldoScreen} options={{
								headerTitle: userNameMitra ? "Kirim Saldo ke " + userNameMitra : "Kirim Saldo",

							}}/>
						</>
				}
			</Stack.Navigator>
		</NavigationContainer>
	)
}

const BottomTab = createBottomTabNavigator<MainTabParamList>();

const MainButtomTab = () => {
	return (
		<BottomTab.Navigator
			initialRouteName={MainTabRoutes.Dashboard}
			screenOptions={{
				tabBarActiveTintColor: "white",
				tabBarInactiveTintColor: Colors.bg.mainSuccessLv1,
				tabBarStyle: {
					height: 80,
					paddingBottom: 0, paddingTop: 7,
					backgroundColor: Colors.bg.mainSuccess,
				},
				tabBarLabelStyle: {
					fontSize: 14,
					fontFamily: 'Poppins_500Medium',
				}
			}}
		>
			<BottomTab.Screen
				name={MainTabRoutes.Dashboard}
				component={HomeScreen}
				options={{
					headerShown: false,
					tabBarLabel: 'Dashboard',
					tabBarIcon: ({color}) => (
						<Ionicons name="home-outline" size={28} color={color}/>
					)
				}}
			/>
			<BottomTab.Screen
				name={MainTabRoutes.Register}
				component={RegisterScreen}
				options={{
					headerShown: true,
					tabBarLabel: '',
					headerTitle: 'Register Mitra',
					headerTitleStyle: {
						fontFamily: 'Poppins_500Medium',
					},
					tabBarIcon: ({color}) => (
						<Ionicons name="ios-add-circle-outline" size={60} color={color}/>
					)
				}}
			/>
			<BottomTab.Screen
				name={MainTabRoutes.Profile}
				component={ProfileScreen}
				options={{
					headerShown: false,
					tabBarLabel: 'Profile',
					tabBarIcon: ({color}) => (
						<Ionicons name="person-outline" size={28} color={color}/>
					)
				}}
			/>
		</BottomTab.Navigator>
	)
}
