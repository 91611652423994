import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {NavigatorScreenParams} from "@react-navigation/native";

declare global {
	namespace ReactNavigation {
		interface RootParamList extends RootStackParamList {
		}
	}
}

export enum MainRoutes {
	Home = 'Home',
	Login = 'Login',
	Kirim = 'Kirim',
}

export enum MainTabRoutes {
	Dashboard = 'Dashboard',
	Profile = 'Profile',
	Register = 'Register',
}


export type RootStackParamList = {
	[MainRoutes.Home]: NavigatorScreenParams<MainTabParamList> | undefined;
	[MainRoutes.Login]: undefined,
	[MainRoutes.Kirim]: {id: number},
}

export type MainTabParamList = {
	[MainTabRoutes.Dashboard]: undefined,
	[MainTabRoutes.Profile]: undefined,
	[MainTabRoutes.Register]: undefined,
}

export type RootStackScreenProps<Screen extends keyof RootStackParamList> = NativeStackScreenProps<RootStackParamList, Screen>
