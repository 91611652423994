import appState from "./state/appState";
import {combineReducers} from "@reduxjs/toolkit";
import user from "./state/user";
import LoginState from "./state/LoginState";
import UserStateReducer from "./state/userState";
import mitraStateReducer from "./state/mitraState";

const rootReducer = combineReducers({
	appState: appState,
	user: user,
	loginState: LoginState,
	userData: UserStateReducer,
	mitra: mitraStateReducer,
});

export default rootReducer;
