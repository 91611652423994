import React from "react";
import {View} from "react-native";
import {ScrollView} from "react-native-gesture-handler";
import {DangerButton} from "../../component/molecules/Button/BaseButton";
import TextPoppinsRegular from "../../component/molecules/Text/PoppinsTextRegular";
import {Colors} from "../../constant/Colors";
import {useReduxDispatch, useReduxSelector} from "../../redux";
import {setLogin} from "../../redux/state/LoginState";
import {getUserData, resetUser} from "../../redux/state/userState";
import {formatRupiah} from "../../util/function/formatRupiah";

export default function ProfileScreen(): React.ReactElement {
	const dispatch = useReduxDispatch();
	const datamember = useReduxSelector(getUserData);
	console.log(datamember);
	return (
		<View style={{justifyContent: "space-between", paddingBottom: 10, flex: 1}}>
			<ScrollView style={{
				backgroundColor: 'white',
			}}>

				<View style={{
					width: "100%",
					backgroundColor: Colors.bg.mainSuccessLv1,
					alignItems: 'center',
					paddingBottom: 10,
					paddingTop: 50,
				}}>
					<TextPoppinsRegular style={{fontSize: 24}}>Profile</TextPoppinsRegular>
				</View>

				<View style={{
					paddingHorizontal: 20,
					marginTop: 20
				}}>
					<View style={{flexDirection: "row"}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Email</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>{datamember.email}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Nama</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>{datamember.nama_lengkap}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Username</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>{datamember.username}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>No HP</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>{datamember.phone}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Saldo</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular
								style={{fontSize: 16}}>Rp. {formatRupiah(datamember.saldo)}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Alamat</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular
								style={{fontSize: 16, flexWrap: 'wrap'}}>{datamember.address}</TextPoppinsRegular>
						</View>
					</View>
					<View style={{flexDirection: "row", marginTop: 10}}>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular style={{fontSize: 16}}>Kode Referral</TextPoppinsRegular>
						</View>
						<View style={{width: '50%'}}>
							<TextPoppinsRegular
								style={{fontSize: 16, flexWrap: 'wrap'}}>{datamember.kode_referral}</TextPoppinsRegular>
						</View>
					</View>
				</View>

			</ScrollView>
			<DangerButton title={"Logout"} onPress={() => {
				dispatch(resetUser());
			}}/>
		</View>
	);
}
