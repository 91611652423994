import Axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {DataLoginResponseType} from "../type/LoginResponseType";
import {Platform} from "react-native";

export const BaseUrl = Platform.OS === "web" ? "https://pub.bangbeli.com/https://prod.bangbeli.com/api/" : "https://prod.bangbeli.com/api/";

export const PlainApiCall = Axios.create({
    baseURL: BaseUrl,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

const AuthorizedApiCall = Axios.create({
    baseURL: BaseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
});

AuthorizedApiCall.interceptors.request.use(
    async (config) => {
        const userData = await AsyncStorage.getItem('userData');
        if (userData) {
            const objUser: DataLoginResponseType = JSON.parse(userData);
            // @ts-ignore
            config.headers.Authorization = `Bearer ${objUser.token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

export const ApiCall = AuthorizedApiCall;


