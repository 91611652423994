import React, {createRef, useEffect} from "react";
import {FlatList, Platform, TextInput, View} from "react-native";
import * as Animatable from "react-native-animatable";

type Props = {
    showInput?: boolean;
    length: number
    finalOtp: (otp: string) => void
    keyboadType?: React.ComponentProps<typeof TextInput>["keyboardType"]
}
export default function OtpInput(props: Props): React.ReactElement {
    const {showInput, length, finalOtp,keyboadType} = props
    const [_otp, _setOtp] = React.useState<string[]>([]);
    const [_activeIndex, _setActiveIndex] = React.useState<number>(0);

    const _arraylength = new Array(length ?? 4).fill(0)

    const _textInput = React.useRef<TextInput[]>([])


    function _focusNext(index: number) {
        if (index < length - 1) {
            _textInput.current[index + 1].focus()
        } else {
            _textInput.current[index].blur()
        }
    }

    function _handleOnChangeText(text: string, index: number) {
        _setOtp((prev) => {
            const _prev = [...prev]
            _prev[index] = text
            return _prev
        })
        if (text.length > 0) {
            _focusNext(index)
        }
    }

    useEffect(() => {
        if (_otp.length === length) {
            finalOtp(_otp.join(''))
        }
    }, [_otp])

    useEffect(() => {
        _textInput.current[0].focus()
    }, []);


    function _renderItem({item, index}: { item: number, index: number }) {
        const _stylesInput = ()=>{
            if(Platform.OS=="web"){
                return {flex: 1, outline: 0, textAlign: "center", fontSize: 24, fontFamily: "Poppins_700Bold"}
            }else{
                return {flex: 1, textAlign: "center", fontSize: 24, fontFamily: "Poppins_700Bold"}
            }
        }
        return (
            <Animatable.View
                animation={_activeIndex === index ? "pulse" : undefined}
                easing={"ease-out"}
                iterationCount={"infinite"}
                iterationDelay={1000}
                style={{
                    width: _activeIndex === index ? 60 : 50,
                    height: _activeIndex === index ? 60 : 50,
                    backgroundColor: 'white',
                    marginRight: 10,
                    borderRadius: 10,
                }}>
                <TextInput
                    value={_otp[index] || ''}
                    secureTextEntry={!showInput}
                    ref={el => _textInput.current[index] = el}
                    style={_stylesInput()}
                    onChangeText={(value) => {
                        console.log(value)
                        if (value.length > 0) {
                            _handleOnChangeText(value, index)
                        } else if (value.length < 1) {
                            _handleOnChangeText(value, index)
                        }
                    }}
                    indicator={false}
                    onKeyPress={(e) => {
                        if (e.nativeEvent.key === "Backspace") {
                            if (_activeIndex === _otp.length) {
                                const _prev = [..._otp]
                                _prev[index] = ""
                                console.log(_prev)
                                _setOtp(_prev)
                            }
                            if (index > 0) {
                                _setActiveIndex(index - 1)
                                _textInput.current[index - 1].focus()
                            }
                        } else {
                            _setActiveIndex(index + 1)
                        }
                    }}
                    keyboardType={keyboadType}
                >

                </TextInput>
            </Animatable.View>
        )
    }

    return (
        <FlatList horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  data={_arraylength}
                  renderItem={_renderItem}
                  contentContainerStyle={{
                      alignItems: "center",
                  }}
        />
    )
}
