import {DataLoginResponseType} from "../../util/type/LoginResponseType";
import {createAction, createReducer} from "@reduxjs/toolkit";
import {RootState} from "../index";

type initialStateTYpe = {
	data: DataLoginResponseType,
	isLogedIn: boolean,
}

const initialState: initialStateTYpe = {
	data: {
		id: 0,
		username: '',
		email: '',
		token: '',
		saldo: 0,
		phone: '',
		address: '',
		status_member: '',
		verifikasi:0,
		nama_lengkap: '',
	},
	isLogedIn: false,
}

export const getUserData = (state: RootState) => state.userData.data;
export const getIsuserLogedIn = (state: RootState) => state.userData.isLogedIn;
export const setUser = createAction('SET_USER', (data: DataLoginResponseType) => ({
	payload: data,
}))

export const setSaldo = createAction('SET_SALDO', (saldo: number) => ({
	payload: saldo,
}))

//reset t initialState
export const resetUser = createAction('RESET_USER')

const UserStateReducer = createReducer(initialState, builder => {
	builder.addCase(setUser, (state, action) => {
		state.data = action.payload
		state.isLogedIn = true
	})
		.addCase(setSaldo, (state, action) => {
			state.data.saldo = action.payload
		})
		.addCase(resetUser, (state) => {
			state.data = initialState.data
			state.isLogedIn = initialState.isLogedIn
		})
})

export default UserStateReducer
