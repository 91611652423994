import {createAction, createReducer} from "@reduxjs/toolkit";
import {RootState} from "../index";

export type mitraStateInitial = {
	username: string;
}
const initialState: mitraStateInitial = {
	username: '',
}

export const getMitraUsername = (props:RootState) => {
	return props.mitra.username
}

export const setusernameMitra = createAction('SET_USERNAME', (username: string) => ({payload: username}));

const mitraStateReducer = createReducer(initialState,builder => {
	builder.addCase(setusernameMitra, (state, action) => {
		state.username = action.payload;
	})
})

export default mitraStateReducer;

