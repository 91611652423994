import React from "react";
import {ActivityIndicator, ScrollView, View} from "react-native";
import {BaseTextInput} from "../../component/molecules/Textinput/BaseTextInput";
import {MainButton} from "../../component/molecules/Button/BaseButton";
import {useToast} from "react-native-toast-notifications";
import {ApiCall} from "../../util/helper/ApiCall";
import {RegisterMitraResponseType} from "../../util/type/mitradataType";
import {Colors} from "../../constant/Colors";

export default function RegisterScreen(): React.ReactElement {
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [passwordConfirm, setPasswordConfirm] = React.useState("");
	const [username, setUserName] = React.useState("");
	const [fullName, setFullName] = React.useState("");
	const [phone, setPhone] = React.useState("");
	const [address, setAddress] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);

	const toast = useToast();

	const handleRegisteMitra = async () => {
		//check if password and confirm password is same and not empty
		if (password === "") {
			toast.show("Password tidak boleh kosong", {type: "danger"});
			return;
		}
		if (password !== passwordConfirm) {
			toast.show("Password and Confirm Password is not same", {type: "danger"});
			return;
		}
		try {
			setIsLoading(true);
			const dataPost = {
				username,
				email,
				password,
				phone,
				nama_lengkap: fullName,
				gender: "",
				address
			}
			const {data} = await ApiCall.post('v1/members/registrasi-premium', dataPost)
			const response: RegisterMitraResponseType = data
			if (response.message === "Success") {
				toast.show("Registrasi berhasil", {type: "success"})
				setUserName("")
				setPasswordConfirm("")
				setPassword("")
				setEmail("")
				setFullName("")
				setPhone("")
				setAddress("")

			} else {
				toast.show(response.message, {type: "danger"})
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<View style={{flex: 1, backgroundColor: "white"}}>
			<ScrollView>
				<View style={{width: "100%", alignItems: "center", paddingHorizontal: 15, paddingVertical: 10}}>
					<BaseTextInput placeholder={"Username"} onChangeText={(text) => {
						setUserName(text);
					}} value={username}/>
					<BaseTextInput placeholder={"E-Mail"} onChangeText={(text) => {
						setEmail(text);
					}} value={email} keyboardType={"email-address"}/>
					<BaseTextInput placeholder={"Phone"} onChangeText={(text) => {
						setPhone(text);
					}} value={phone}/>
					<BaseTextInput placeholder={"Nama Lengkap"} onChangeText={(text) => {
						setFullName(text);
					}} value={fullName}/>
					<BaseTextInput placeholder={"Alamat"} onChangeText={(text) => {
						setAddress(text);
					}} value={address}/>
					<BaseTextInput placeholder={"Password"} onChangeText={(text) => {
						setPassword(text);
					}} value={password} secureTextEntry={true}/>
					<BaseTextInput placeholder={"Password Confirmation"} onChangeText={(text) => {
						setPasswordConfirm(text);
					}} value={passwordConfirm} secureTextEntry={true}/>
					{
						isLoading
							?
							<ActivityIndicator size="large" color={Colors.bg.mainSuccessLv1}/>
							:
							<MainButton title={"Register"} onPress={handleRegisteMitra}/>
					}
				</View>
			</ScrollView>
		</View>
	)
}
